import React from 'react';

const Star = ({ className = '', width, height, color }) => {
  return (
    <svg
      width={width ? width : '26'}
      height={height ? height : '25'}
      viewBox="0 0 26 25"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M25.9558324 9.41926595c-.1177803-.47542037-.4711212-.71313055-.9422424-.83198564l-7.5379388-1.06969582L14.0600227.62398923c-.3533409-.83198564-1.7667045-.83198564-2.1200454 0L8.52434881 7.3987294 1.10419026 8.58728031c-.47112118 0-.82446206.35656527-1.06002265.83198564-.1177803.47542036 0 .95084075.35334088 1.18855095l5.41789355 5.348479-1.29558324 7.6067259c-.11778029.4754203.11778029.9508407.47112118 1.1885509.35334088.2377101.82446206.3565652 1.29558324.1188551L13 21.304775l6.7134768 3.5656528h.5889015c.2355605 0 .4711211-.1188551.7066817-.2377102.3533409-.2377102.5889015-.7131306.4711212-1.1885509l-1.2955832-7.6067258 5.4178935-5.3484791c.3533409-.2377102.4711212-.71313058.3533409-1.06969585zM9.73833544 14.5417867c-.26834748.3565653-6.03642776 1.4454297 2.28234396 1.3111144l-1.0677099.9158487 3.8788074 2.0400683c-.4025212-.2377102-.8050424-.2377102-1.2075636 0l-5.90364445 2.7336671 6.57451315-3.0651875c0-.3565653-2.6834748-9.27684703-2.9518222-9.6334123l-7.64790306 1.7639315 6.57451316-.83198568c.4025212 0 .8050424-.35656527 1.0733899-.59427545L13.6242133 11l3.6226909-1.93729933c.1341737.35656528-6.8306296-.63421319-6.2939347-.63421319L7.86682278 15.3057511l12.19772992-.6568612-10.32621726-.1071032z"
        fill={!color ? '#FCC01A' : color}
        fillRule="nonzero"
      />
    </svg>
  );
};
export default Star;
