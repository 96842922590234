/* --- import Styles ----*/
import { BtnWrapper, HeroHomeWrapper, HeroLeft } from 'src/components/Hero/elements';
import { Button, MobileApplyBTN } from 'src/components/Button';
import { HeaderDescription, HeaderTitle } from 'src/components/Typography';
import { InstagramFeed, LatestsPosts, OnlineOnsite, StackTech } from 'src/components/Home';
import { useEffect, useState } from 'react';

import { Founders } from '../components/Founders/index';
/* --- import Components ----*/
import Layout from 'src/components/Layout';
import { MainVideo } from 'src/components/Projects';
import ModalForm from 'src/components/Modal/ModalFormSyllabus';
import { PartnersSection } from 'src/components/Partners';
// eslint-disable-next-line
import React from 'react';
import { RecruitersSection } from 'src/components/Partners';
import { Section } from 'src/components/Section';
// import { PodcastSection } from 'src/components/Podcast';
import { Testimonials } from '../components/Bootcamp/Testimonials/';
import { TopbarStaticSpace } from 'src/components/Topbar';
import { graphql } from 'gatsby';
/* --- import Media Queries ----*/
import { mediaQueries } from 'src/style/breakpoints';

const HomePage = ({
  data,
  data: {
    allMarkdownRemark: { edges },
  },
  location,
  // pageContext: { nextSessions, dataFromMeetup },
}) => {
  // const [fullTimeSesssions, setFullTimeSessions] = useState([]);
  // const [partTimeSesssions, setPartTimeSessions] = useState([]);
  const [buttonVisible, setButtonVisible] = useState(false);

  const [isModalFormOpen, setIsModalFormOpen] = useState(false);
  const [formSuccess, setFormSuccess] = useState(false);
  const [values, setValues] = useState();

  // const initSessionsState = useCallback(() => {
  //   let newFullTimeSessions = [];
  //   let newPartTimeSessions = [];
  //   nextSessions.map((item, index) => {
  //     if (item.format && item.format === 'full-time') {
  //       newFullTimeSessions.push(item, index);
  //       return newFullTimeSessions;
  //     } else if (item.format && item.format === 'part-time') {
  //       newPartTimeSessions.push(item, index);
  //       return newPartTimeSessions;
  //     } else {
  //       return null;
  //     }
  //   });
  //   setFullTimeSessions(newFullTimeSessions);
  //   setPartTimeSessions(newPartTimeSessions);
  // }, [nextSessions]);

  useEffect(() => {
    // initSessionsState();
    const handleScroll = () => {
      if (global.window && window.pageYOffset >= 150) {
        return setButtonVisible(true);
      } else {
        return setButtonVisible(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  edges = edges.filter((edge) => edge.node.frontmatter.published === true).slice(0, 6);

  return (
    <Layout
      pathname={location.pathname}
      businessType="B2C|B2B"
      pageName="Homepage"
      pageType="homepage"
      metaTitle="Formation Développeur Web et Mobile | Le Reacteur"
      metaDescription="Le Reacteur est la première formation développeur Web et Mobile JavaScript intensive en France. À temps plein, à temps partiel ou en alternance, apprenez à coder !"
      metaVideo="https://www.youtube.com/embed/59XH_-eMQhE"
    >
      <TopbarStaticSpace />
      {/* HERO HOME */}
      <Section
        theme="primary"
        home
        hero
        css={{
          [mediaQueries.desktopUp]: {
            minHeight: '700px',
          },
          [mediaQueries.bigDesktopUp]: {
            maxHeight: '800px',
          },
        }}
      >
        <HeroHomeWrapper>
          <HeroLeft>
            <HeaderTitle>
              <p
                css={{
                  [mediaQueries.phoneOnly]: {
                    display: 'none',
                  },
                  [mediaQueries.tabletPortraitUp]: {
                    display: 'none',
                  },
                  [mediaQueries.tabletLandscapeUp]: {
                    display: 'block',
                  },
                  [mediaQueries.desktopUp]: {
                    display: 'none',
                  },
                  [mediaQueries.mediumDesktopUp]: {
                    display: 'block',
                  },
                }}
              >
                Faîtes décoller votre carrière,
                <br />
                apprenez à coder.
              </p>
              <p
                css={{
                  [mediaQueries.phoneOnly]: {
                    display: 'block',
                  },
                  [mediaQueries.tabletPortraitUp]: {
                    display: 'block',
                  },
                  [mediaQueries.tabletLandscapeUp]: {
                    display: 'none',
                  },
                  [mediaQueries.desktopUp]: {
                    display: 'block',
                  },
                  [mediaQueries.mediumDesktopUp]: {
                    display: 'none',
                  },
                }}
              >
                Faîtes décoller
                <br />
                votre carrière,
                <br />
                apprenez à coder
              </p>
            </HeaderTitle>
            <HeaderDescription
              css={{
                marginTop: 30,
                textAlign: 'center',
                [mediaQueries.desktopUp]: {
                  marginRight: 60,
                  textAlign: 'left',
                },
              }}
            >
              Depuis 2016, nous formons des profils débutants et confirmés, à la programmation.
              Notre enseignement est basé sur la réalisation de projets réels et concrets, en
              fournissant un accompagnement constant et particulièrement soutenu à nos apprenants.
            </HeaderDescription>

            <BtnWrapper>
              <Button
                css={{
                  minWidth: 210,
                }}
                onClick={() => {
                  setIsModalFormOpen(!isModalFormOpen);
                }}
              >
                Recevoir le programme{' '}
                <br
                  css={{
                    [mediaQueries.tabletPortraitUp]: {
                      display: 'none',
                    },
                  }}
                />{' '}
                de formation
              </Button>
            </BtnWrapper>
            {isModalFormOpen && (
              <>
                <ModalForm
                  isOpen={isModalFormOpen}
                  onClose={() => {
                    setFormSuccess(false);
                    setIsModalFormOpen(false);
                  }}
                  formSuccess={formSuccess}
                  setFormSuccess={setFormSuccess}
                  values={values}
                  setValues={setValues}
                />
              </>
            )}
          </HeroLeft>
          <MainVideo
            mainThumbnail={data.heroimage.childImageSharp.fluid}
            link="https://www.youtube.com/embed/59XH_-eMQhE"
          />
        </HeroHomeWrapper>
      </Section>
      <OnlineOnsite />
      <StackTech theme="secondary" />
      {/* <DiplomaSection /> */}
      {/* <BestBootcamp /> */}
      <Founders
        portraitXavier={data.xavier.childImageSharp.gatsbyImageData}
        portraitFarid={data.farid.childImageSharp.gatsbyImageData}
        id="founders"
      />
      <PartnersSection theme="secondary" title="Ils nous soutiennent" />
      <Testimonials />
      <RecruitersSection theme="secondary" />
      {/* <EventsSection column home dataFromMeetup={dataFromMeetup} id="evenements" /> */}
      {/* <PodcastSection /> */}
      <LatestsPosts data={edges} />
      <InstagramFeed theme="secondary" />
      {/* <CTACourses fullTimeSesssions={fullTimeSesssions} partTimeSesssions={partTimeSesssions} /> */}
      <MobileApplyBTN buttonVisible={buttonVisible} />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify([
            {
              '@context': 'https://schema.org',
              '@type': 'EducationalOrganization',
              name: 'Le Reacteur',
              alternateName: 'Le Reacteur - Coding Bootcamp & Alternance',
              url: 'https://www.lereacteur.io',
              logo: 'https://www.lereacteur.io/images/lereacteur_rond_violet.png',
              description:
                'Le Reacteur est la première formation développeur Web et Mobile JavaScript intensive à Paris. À temps plein, à temps partiel ou en alternance, apprenez à coder !',
              sameAs: [
                'https://www.facebook.com/LeReacteurIO',
                'https://twitter.com/LeReacteurIO',
                'https://linkedin.com/company/le-reacteur',
                'https://www.instagram.com/lereacteur.io',
              ],
              founders: [
                {
                  '@type': 'Person',
                  name: 'Xavier Colombel',
                  sameAs: [
                    'https://www.linkedin.com/in/xcolombel/',
                    /* 'https://medium.com/@XavierColombel', */
                    'https://github.com/XavierColombel',
                  ],
                },
                {
                  '@type': 'Person',
                  name: 'Farid Safi',
                  sameAs: [
                    'https://www.linkedin.com/in/faridsafi/',
                    'https://github.com/FaridSafi',
                  ],
                },
              ],
              contactPoint: {
                '@type': 'ContactPoint',
                contactType: 'customer service',
                email: 'contact@lereacteur.io',
                url: 'https://www.lereacteur.io/contact/',
                areaServed: 'FR',
                availableLanguage: 'fr',
              },
            },
          ]),
        }}
      />
    </Layout>
  );
};

export const query = graphql`
  query {
    heroimage: file(relativePath: { eq: "2023_students6.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    # imageFormationAdapte: file(relativePath: { eq: "image05.jpg" }) {
    #   childImageSharp {
    #     fluid(quality: 70, maxWidth: 550) {
    #       ...GatsbyImageSharpFluid_withWebp
    #     }
    #   }
    # }
    farid: file(relativePath: { eq: "2023_Farid.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 50, width: 300, placeholder: NONE)
      }
    }
    xavier: file(relativePath: { eq: "2023_Xavier.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 50, width: 300, placeholder: NONE)
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, limit: 10) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "DD MMMM YYYY", locale: "fr")
            title
            author
            featuredImage {
              childImageSharp {
                gatsbyImageData(width: 970)
              }
            }
            tags
            preview
            published
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;

export default HomePage;
